<template>
  <nav class="relative z-10 lg:-translate-y-1/2">
    <div>
      <div
        class="fixed inset-0 z-10 hidden bg-black/20"
        :class="{'max-lg:block': showMenu}"
      />
      <button
        id="menu-button"
        type="button"
        :class="{'rounded-b-none border-b-0': showMenu}"
        class="text-gray-700 relative z-20 inline-flex w-full items-center justify-between rounded-md border border-gray-lightest bg-white p-4 text-left font-medium focus:outline-none lg:hidden"
        :aria-expanded="showMenu"
        aria-haspopup="true"
        @click="showMenu = !showMenu"
      >
        {{ page?.title || '' }}
        <svg
          class="-mr-1 ml-2 h-5 w-5 text-gray/80"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <ul
      class="top-0 z-20 divide-gray-lightest rounded-lg border border-gray-lightest bg-white max-lg:absolute max-lg:-mt-px max-lg:w-full max-lg:divide-y max-lg:border-t-0 max-lg:px-4 max-lg:font-medium lg:flex lg:divide-x"
      :class="{'max-lg:hidden': !showMenu}"
    >
      <li
        v-for="(item, index) in nav"
        :key="item.url"
        class="group relative w-full max-sm:snap-center lg:flex-1"
      >
        <NuxtLink
          :to="item?.url"
          exact-active-class="border-b-2 border-yellow"
          class="flex items-center gap-3 truncate border-yellow py-4 hover:border-b-2 max-lg:flex-wrap lg:justify-center lg:p-8"
          @click="showMenu = false"
        >
          <div class="flex w-full items-center justify-between">
            {{ item.label }}
            <IconsChevronDown
              v-if="item.children?.length && index !== 0"
              class="h-5 w-5 max-lg:hidden"
            />
          </div>
          <div class="flex-basis-full lg:absolute lg:left-0 lg:top-full lg:hidden lg:min-w-full lg:pt-1 lg:group-hover:block">
            <ul
              v-if="item.children && index !== 0"
              class="space-y-3 max-lg:border-l max-lg:border-yellow max-lg:pl-2 max-lg:leading-none lg:space-y-2 lg:divide-y lg:divide-gray-lightest lg:rounded-lg lg:bg-white lg:shadow-floating"
            >
              <li
                v-for="child in item.children"
                :key="child.url"
              >
                <NuxtLink
                  :to="child.url"
                  class="block py-1 hover:underline max-lg:font-normal lg:p-4"
                >
                  {{ child.label }}
                </NuxtLink>
              </li>
            </ul>
          </div>
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
const showMenu = ref(false);
const {menus} = useSofieMenus();

const page = useSofiePageWhenAvailable();

const props = defineProps<{
  menu: string
}>();

const nav = computed(() => {
  return menus.value?.[props.menu];
});
</script>
